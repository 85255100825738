import type { FC } from 'react'

import type { NextPageContext } from 'next'

import { pruneNilValues } from '@core/object'
import MicrositePage from '@microsites/components/MicrositePage'
import type { IMicrositePageProps } from '@microsites/types'
import { getMicrositeInitialProps } from '@microsites/utils/microsites/getMicrositeInitialProps'
import { redirectToChannelSubdomain } from '@microsites/utils/microsites/routing'

/**
 * Channel page that redirects to the channel subdomain.
 */
const ChannelRedirect: FC<IMicrositePageProps> = (props) => {
  if (props.ssrPage) {
    return <MicrositePage {...props} />
  }
  return null
}

/**
 * Catch-all route for channel pages, that redirects to the channel subdomain
 */
export const getServerSideProps = async (context: NextPageContext) => {
  const props = await getMicrositeInitialProps(context)
  if (!props.ssrChannel) {
    redirectToChannelSubdomain(context)
    return { props: {} }
  }
  return { props: pruneNilValues(props) }
}

export default ChannelRedirect
